import React, { useEffect } from 'react';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import Aos from 'aos';
import { Helmet } from 'react-helmet';

const containerStyle = {
      backgroundColor:"white",
  padding: '20px',
  borderRadius: '8px',
  paddingTop:"10px",
//   maxWidth: '900px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 1)',
  fontFamily: 'Poppins, sans-serif',
};

function Imprint() {
      useEffect(() => {
            Aos.init(); // Initialize AOS
          }, []);
  return (
    <>
    <Helmet>
  <title>Impressum - Getgutschein</title>
  <meta name="description" content="Erfahren Sie mehr über das Impressum von Getgutschein.de, inklusive Unternehmensinformationen, Kontaktangaben und rechtlichen Hinweisen. Kontaktieren Sie uns für Fragen zu unseren Angeboten." />
  <meta name="keywords" content="Impressum, Unternehmensinformationen, Kontaktangaben, rechtliche Hinweise, Getgutschein, Adresse, Dubai, Umar Saleem, E-Mail, Telefon, Geschäftsführung" />
</Helmet>
      <Navbar />
      <div style={{ backgroundColor: "white",padding:20}}>
        <div className="container" >
            <div style={containerStyle} data-aos="zoom-out-down">

         
          <h2 style={{ color: 'black' }}>Impressum</h2>

          <p style={{ color: 'black', lineHeight: '1.6' }}>
            <strong>Adresse des Unternehmens:</strong><br />
            27 Straße 21A - Al Manara, Dubai
          </p>

          <p style={{ color: 'black', lineHeight: '1.6' }}>
            <strong>Leitung:</strong><br />
            Umar Saleem (Geschäftsführer)
          </p>

          <p style={{ color: 'black', lineHeight: '1.6' }}>
            <strong>Website:</strong><br />
            <a href="https://getgutschein.de/" target="_blank" rel="noopener noreferrer">https://getgutschein.de/</a>
          </p>

          <p style={{ color: 'black', lineHeight: '1.6' }}>
            <strong>Kontakt:</strong><br />
            Telefon: +96894121522<br />
            E-Mail: <a href="mailto:getgutschein@gmail.com">getgutschein@gmail.com</a>
          </p>

          <p style={{ color: 'black', lineHeight: '1.6' }}>
            <strong>Unterstützung:</strong><br />
            Email: <a href="mailto:getgutschein@gmail.com">getgutschein@gmail.com</a>
          </p>

          <p style={{ color: 'black', lineHeight: '1.6' }}>
            <strong>Hinweis:</strong><br />
            Bei getgutschein werden die Angebote manuell recherchiert, es gibt keine Garantie, dass die angezeigten Angebote aktuell sind. Sollten Sie Probleme mit einem Produkt haben, wenden Sie sich bitte direkt an den Anbieter des Angebots direkt. Wenn Sie uns zu einem Angebot kontaktieren möchten, wenden Sie sich am besten per E-Mail an uns.
          </p>

       
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Imprint;
