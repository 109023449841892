import React, { useState, useEffect } from 'react';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { baseUrl } from '../BaseUrl';

function ViewCategories() {
  const navigate = useNavigate();
  const { categoryNameid } = useParams(); // Get category ID from the URL
  const [selectedStores, setSelectedStores] = useState([]);
  const [loading, setLoading] = useState(true);

  // Function to fetch stores based on category ID from URL
  const openviewSelectedStore = async (categoryNameid) => {
    try {
      setLoading(true);
      // Fetch stores based on the category ID
      const response = await axios.get(`${baseUrl}api/AddStore/Categories/${categoryNameid}`);
      const storeIds = response.data.storeIds;
      
      if (Array.isArray(storeIds)) {
        const uniqueStoreIds = [...new Set(storeIds.flat())];
        const storePromises = uniqueStoreIds.map(id => axios.get(`${baseUrl}api/createStore/${id}`));

        // Use Promise.all to fetch all stores concurrently
        const storeResponses = await Promise.all(storePromises);

        // Extract data and store in state
        setSelectedStores(storeResponses.map(response => response.data));
      } else {
        setSelectedStores([response.data.storeIds]);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch stores when the component mounts or categoryNameid changes
  useEffect(() => {
    openviewSelectedStore(categoryNameid); // Fetch data based on URL param
  }, [categoryNameid]); // Refetch data if categoryNameid changes

  // Handle store click and navigate to store page with store ID in URL
  const handleStoreClick = (store) => {
    navigate(`/OpenStore/${store.id}/${store.storeName}`);
  };

  return (
    <div>
      <Navbar />
      <Helmet>
        <title> Categories - Getgutschein</title>
        <meta name='description' content='Genießen Sie die besten Angebote und Verkäufe mit saisonalen Gutscheinen und Rabattcodes. Viele nicht saisonale Rabattangebote' />
      </Helmet>

      <div className='container'>
        <div className="row">
          <div className="col-12">
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <img src='/categories.gif' style={{ width: 100, height: 100 }} alt="Loading" />
              </div>
            ) : (
              <div className="row" style={{ marginTop: "3%" }}>
                {selectedStores.map(store => (
                  <div
                    key={store.id}
                    className="col-md-3 mb-4"
                    data-aos="zoom-in-up"
                  >
                    <div
                      className="card"
                      style={{
                        cursor: 'pointer',
                        border: 'none',
                        backgroundColor: "transparent",
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 1)',
                        transition: 'box-shadow 0.3s ease-in-out'
                      }}
                      onClick={() => handleStoreClick(store)}
                    >
                      <div
                        className="card-img-container"
                        style={{
                          width: '100%',
                          height: '180px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 5,
                          border: '1px solid #9ea0a0',
                          marginBottom: '8px',
                          backgroundColor: 'White'
                        }}
                      >
                        <img
                          src={store.StoreImage}
                          alt={store.storeName}
                          className="card-img"
                          loading="lazy" // Lazy load images for better performance
                          style={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            objectFit: "contain"
                          }}
                        />
                      </div>
                      <div
                        className="card-body text-center"
                        style={{
                          backgroundColor: 'White',
                          borderRadius: 5,
                          border: '1px solid #9ea0a0',
                          padding: '7px'
                        }}
                      >
                        <h5 className="card-title">{store.storeName}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ViewCategories;
