import React, { useEffect } from 'react';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import Aos from 'aos';
import { Helmet } from 'react-helmet';
const containerStyle = {
      backgroundColor:"white",
  padding: '20px',
  borderRadius: '8px',
  paddingTop:"10px",
//   maxWidth: '900px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 1)',
  fontFamily: 'Poppins, sans-serif',
};
function Dataprotection() {
      useEffect(() => {
            Aos.init(); // Initialize AOS
          }, []);
  return (
      <>
 <Helmet>
  <title>Datenschutzrichtlinie - Getgutschein</title>
  <meta name="description" content="Erfahren Sie mehr über die Datenschutzrichtlinien von Getgutschein.de. Wir sammeln keine persönlichen Daten und schützen die Privatsphäre unserer Nutzer. Lesen Sie mehr über unsere Richtlinien für Tracking-Technologien und Cookies." />
  <meta name="keywords" content="Datenschutz, Datenschutzrichtlinie, Cookies, Tracking-Technologien, Privatsphäre, persönliche Daten, Sicherheit, Getgutschein" />
</Helmet>
    <Navbar/>
    <div style={{ backgroundColor: "white",padding:20}}>
    <div className="container" >
    <div style={containerStyle} data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
      <h2>Dataprotection</h2>

      <h3>Was ist Get Gutschein.de?</h3>
      <p >
        Gutschein.de ist bekannt als die beste Gutscheinseite. Hier finden Sie 100% echte Gutscheine und Sale-Angebote 
        für Ihren Einkaufswagen. Sie können damit eine Menge sparen. Getgutschein.de ist eine Drittanbieter-Website, 
        die nur Sales und keine Produkte anbietet. Hier finden Sie verschiedene Markenverkaufsangebote, die über 
        verschiedene Netzwerke mit uns verbunden sind. Wenn jemand einen Gutscheincode erhält und auf die Seite des 
        Markenshops geht, um seinen Gutscheincode einzulösen und etwas kauft, erhält getgutschein.de eine Provision 
        von der Marke. Auf diese Weise erhalten die Kunden einen Rabatt auf ihre Einkäufe.
      </p>

      <h3>Was ist der Zweck von Getgutschein.de?</h3>
      <p>
        Der Hauptzweck von Getgutschein.de ist es, seinen Kunden die besten Gutscheincodes und Angebote zur Verfügung 
        zu stellen. Um einen guten Eindruck bei den Nutzern zu hinterlassen, arbeiten sie daran, die besten Gutscheine 
        für sie zu finden.
      </p>

      <h3>Was ist mit der Datenschutzrichtlinie für Benutzer?</h3>
      <p>
        Diese Datenschutzrichtlinie beschreibt unsere Richtlinien und Verfahren für die Sammlung, Verwendung und 
        Weitergabe Ihrer Daten, wenn Sie den Service nutzen, und informiert Sie über Ihre Datenschutzrechte und wie 
        das Gesetz Sie schützt.
      </p>
      <p>
        Wie Sie wissen, ist Getgutschein.de eine Gutscheinseite, die niemals nach persönlichen Daten fragt. Ihre 
        Privatsphäre ist uns wichtig, deshalb bieten wir Ihnen auf dieser Seite nicht nur die Informationen zum 
        Datenschutz, sondern auch die Möglichkeit, die Art und Weise, wie getgutschein.de funktioniert, maßgeblich 
        zu beeinflussen.
      </p>
      <p>
        Wir brauchen keine persönlichen Daten von unseren Nutzern. Getgutschein.de ist eine vollständig sichere Website, 
        und wir sind stets darauf bedacht, die Rechte und die Privatsphäre unserer Nutzer zu schützen. Wenn Sie als Kunde 
        den Newsletter von Getgutschein.de abonnieren, bleiben Sie immer auf dem Laufenden über alle neuen Aktionen.
      </p>

      <h3>Was ist mit Tracking-Technologien und Cookies?</h3>
      <p>
        Cookies oder Browser-Cookies. Ein Cookie ist eine kleine Datei, die auf Ihrem Gerät abgelegt wird. Sie können 
        Ihren Browser anweisen, alle Cookies abzulehnen oder anzuzeigen, wenn ein Cookie gesendet wird. Wenn Sie jedoch 
        keine Cookies akzeptieren, können Sie möglicherweise einige Teile unseres Dienstes nicht nutzen. Sofern Sie Ihren 
        Browser nicht so eingestellt haben, dass er Cookies ablehnt, kann unser Dienst Cookies verwenden.
      </p>
      <p>
        Wir verwenden Cookies und ähnliche Tracking-Technologien, um die Aktivitäten in unserem Dienst zu verfolgen und 
        bestimmte Informationen zu speichern. Bei den verwendeten Tracking-Technologien handelt es sich um Beacons, 
        Tags und Skripte zur Erfassung und Verfolgung von Informationen sowie zur Verbesserung und Analyse unseres 
        Dienstes. Zu den von uns verwendeten Technologien können gehören. Cookies können „permanente“ oder „Sitzungs“-Cookies 
        sein. Dauerhafte Cookies verbleiben auf Ihrem Computer oder Mobilgerät, wenn Sie offline gehen, während Sitzungscookies 
        gelöscht werden, sobald Sie Ihren Webbrowser schließen.
      </p>
      <p>
        Für andere Zwecke: Wir können Ihre Informationen für andere Zwecke verwenden, wie z. B. zur Datenanalyse, zur Ermittlung 
        von Nutzungstrends, zur Bestimmung der Wirksamkeit unserer Werbekampagnen und zur Bewertung und Verbesserung unseres 
        Dienstes, unserer Produkte, Dienstleistungen, unseres Marketings und Ihrer Erfahrungen.
      </p>
      <p>
        Einer gesetzlichen Verpflichtung nachkommen: die Rechte oder das Eigentum des Unternehmens zu schützen und zu verteidigen; 
        Verhinderung oder Untersuchung möglichen Fehlverhaltens in Verbindung mit dem Dienst; die persönliche Sicherheit der Nutzer 
        des Dienstes oder der Öffentlichkeit zu schützen; Schutz vor rechtlicher Haftung.
      </p>

      <h3>Informationen über Getgutschein</h3>
      <div data-aos="zoom-out-down">
      <p>
        <strong>WICHTIGER HINWEIS:</strong><br />
        Unser Service kann Links zu anderen Websites enthalten, die nicht von uns betrieben werden. Wenn Sie auf einen Link einer 
        dritten Partei klicken, werden Sie auf die Seite dieser dritten Partei weitergeleitet. Wir empfehlen Ihnen dringend, die 
        Datenschutzrichtlinien jeder von Ihnen besuchten Website zu lesen. Wir haben keine Kontrolle über und übernehmen keine 
        Verantwortung für den Inhalt, die Datenschutzrichtlinien oder Praktiken von Websites oder Diensten Dritter.
      </p>
      </div>
      <h3>Kontaktieren Sie uns:</h3>
      <div data-aos="zoom-out-down">
      <p>
        Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, können Sie sich an uns wenden:<br />
        Indem Sie diese Seite auf unserer Website besuchen: <a href="https://getgutschein.de/" target="_blank" rel="noopener noreferrer">https://getgutschein.de/</a><br />
        E-Mail: <a href="mailto:getgutchein@gmail.com">getgutschein@gmail.com</a>
      </p>
      </div>
    </div>
    </div>
    </div>
    <Footer/>
    </>
  );
}

export default Dataprotection;
