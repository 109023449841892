import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, getDocs, query, orderBy } from 'firebase/firestore';
import Spinner from 'react-bootstrap/Spinner';


import Navbar from './Component/Navbar';
import { FaEuroSign, FaCopy } from "react-icons/fa";
import { BsPercent } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Footer from './Component/Footer';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { baseUrl } from '../BaseUrl';
import { useLocation } from 'react-router-dom';
// Helper function to truncate text to a specified word limit


function OpenStore() {
  const location = useLocation();
  const store = location.state;
  // console.log(store)
  const { storeId, storeName } = useParams();
  // console.log(storeId)
  const [storeData, setStoreData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [storeDescription,setStoreDescription]=useState()
  const fetchStoreData = async () => {
    try {
      setLoading(true);
  
      // Fetch store data
      const storeResponse = await axios.get(`${baseUrl}api/createStore/${storeId}`);
      const storeData = storeResponse.data;
  
      // Fetch store description data
      const descriptionResponse = await axios.get(`${baseUrl}api/getDescription/${storeId}`);
      const storeDescription = descriptionResponse.data?.StoreDescription || ''; // Check for store description
      setStoreDescription(storeDescription); // Set store description in state
  
      // Fetch coupon data
      const couponResponse = await axios.get(`${baseUrl}api/coupons/store/${storeId}`);
      const coupons = couponResponse.data;
      
      // Sort the coupons by creation time in descending order
      const sortedCoupons = coupons.sort((a, b) => new Date(b.createtime) - new Date(a.createtime));
  
      // Fetch sales data
      const saleResponse = await axios.get(`${baseUrl}api/Sales/store/${storeId}`);
      const sales = saleResponse.data;
      
      // Sort the sales by creation time in descending order
      const sortedSales = sales.sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
  
      // Set the store data, sorted coupons, and sorted sales into state
      setStoreData({
        ...storeData,
        coupons: sortedCoupons, // Add the sorted coupons to storeData
        sales: sortedSales,     // Add the sorted sales to storeData
      });
  
    } catch (error) {
      console.error('Error fetching store data: ', error);
    } finally {
      setLoading(false); // Stop the loading spinner once the API requests are complete
    }
  };
  
  useEffect(() => {
    fetchStoreData();
  }, []);
  

  
  const [activeTab, setActiveTab] = useState('All');
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleUseCouponClick = (coupon) => {
    setSelectedCoupon(coupon);
    setShowCouponModal(true);
  };

  const handleCloseCouponModal = () => {
    setShowCouponModal(false);
  }

  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    if (selectedCoupon?.couponCode) {
      navigator.clipboard.writeText(selectedCoupon.couponCode).then(() => {
        setCopied(true);
        // setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      });
    }
  };

  const nestCloseCouponModal = (selectedCoupon) => {
    if (selectedCoupon?.CouponCode) {
      // Copy the coupon code to the clipboard
      navigator.clipboard.writeText(selectedCoupon.CouponCode).then(() => {
        // console.log(`Coupon code "${selectedCoupon.CouponCode}" copied to clipboard!`);
      }).catch(err => {
        console.error('Failed to copy coupon code: ', err);
      });
    }
  
    // Open the store URL in a new tab if it exists
    if (selectedCoupon?.StoreURL) {
      window.open(selectedCoupon.StoreURL, '_blank');
    }
  }

  const handleShopNowClick = (sale) => {
    handleCopyClick();
    window.open(sale.StoreURL, '_blank');
  };

  const couponCount = storeData?.coupons?.length || 0;
  const salesCount = storeData?.sales?.length || 0;
  const totalcount = couponCount + salesCount;

// console.log(storeData)

  return (
    <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Helmet>
     
      {loading!=true?<title> {storeData.addMetaTaq}-Getgutschein </title>:<title>Getgutschein</title>}
        <meta name='description' content={loading!=true?<>{storeData.storeSEODes}</>:<>Genießen Sie die besten Angebote und Verkäufe mit saisonalen Gutscheinen und Rabattcodes. Viele nicht saisonale Rabattangebote</>}/>
      </Helmet>
      <Navbar />
      <div className='container mt-5'>
      {loading!=true?<> <div className='row justify-content-center align-items-center'>
          <div className='col-12 col-md-3 text-center' style={{ fontFamily: "Poppins" }}>
            <img src={storeData.StoreImage} alt={storeData.storeName} style={{ width: "70%", height: "70px" }} className="img-fluid" />
          </div>
          <div className='col-12 col-md-7' style={{ textAlign: 'left', fontFamily: "Poppins" }}>
            <h5 className="display-8">{storeData.storeTittle}</h5>
            <p style={{fontFamily:"Poppins",fontSize:16,fontWeight:"400"}}><b style={{fontWeight:"bold",color:"#fb7b1c"}}>{totalcount} Active</b> {storeData.storeSecondTittle}</p>
            
          </div>
          <hr style={{ borderTop: '0.5px solid #CACACE', width: "80%", }} />
        </div>
        <div className='row justify-content-center align-items-center'>
          <div className='col-10' style={{ textAlign: 'left', fontFamily: "Poppins" }}>
            <div className='row'>
              <div className='col-6 col-md-3'>
                <button
                  onClick={() => handleTabChange('All')}
                  className={`btn ${activeTab === 'All' ? 'btn-primary' : 'btn-secondary'} w-100 my-2`}
                >
                  All ({totalcount})
                </button>
              </div>
              <div className='col-6 col-md-3'>
                <button
                  onClick={() => handleTabChange('Code')}
                  className={`btn ${activeTab === 'Code' ? 'btn-primary' : 'btn-secondary'} w-100 my-2`}
                >
                  Code ({couponCount})
                </button>
              </div>
              <div className='col-6 col-md-3'>
                <button
                  onClick={() => handleTabChange('Sales')}
                  className={`btn ${activeTab === 'Sales' ? 'btn-primary' : 'btn-secondary'} w-100 my-2`}
                >
                  Sales ({salesCount})
                </button>
              </div>
              <div className='col-6 col-md-3'>
                <button
                  onClick={() => handleTabChange('Printable')}
                  className={`btn ${activeTab === 'Printable' ? 'btn-primary' : 'btn-secondary'} w-100 my-2`}
                >
                  Printable
                </button>
              </div>
            </div>
          </div>
        </div>
        
 <div className='row justify-content-center align-items-center mt-4'>
          <div className='col-10'>
            {activeTab === 'All' && (
              <>
                {storeData?.coupons?.map((coupon, index) => (
                  <div key={index} className='w-100 mb-4' style={{ backgroundColor: "white", borderRadius: 7, boxShadow: '0 5px 8px #ddd', padding: '1rem', position: 'relative' }}>
                    {coupon.topOffer == "true" ? (
                      <div style={{ 
                        position: 'absolute', 
                        top: 0, 
                        left: 0, 
                        backgroundColor: "red", 
                        color: "white", 
                        fontFamily: "Poppine", 
                        fontWeight: "bold", 
                        fontSize: "1rem", 
                        padding: '0.1rem 1rem',
                        borderRadius: '7px 0 7px 0'
                      }}>
                        {coupon.ConfirmOffer}
                      </div>
                    ):<></>}
                    <div className='row' style={{ height: '100%', marginTop: coupon.addTopOffer ? '2rem' : '0' }}>
                      <div className='col-12 col-md-2 d-flex flex-column align-items-center justify-content-center' style={{ borderRight: "2px dashed blue", height: '100%',top:8 }}>
                        <div style={{ fontFamily: 'cursive', fontSize: '30px', color: 'blue', fontWeight: 'bolder',top:8 }}>
                          {coupon.Discounts}
                          {coupon.DiscountsIn && (
                            coupon.DiscountsIn === 'Euro' ? <FaEuroSign /> : <BsPercent />
                          )}
                        </div>
                        <h4 style={{ fontSize: "30px" }}>{coupon.DiscountsHeading}</h4>
                      </div>
                      <div className='col-12 col-md-7 d-flex flex-column justify-content-center'>
                        <div className="d-flex align-items-center">
                          <h3 style={{ fontFamily: "sans-serif", fontSize: "18px", fontWeight: "bold" }}>{coupon.TitleCoupon}</h3>
                        </div>
                        <p style={{ fontSize: "15px", fontWeight: "normal" }}>{coupon.CouponDescription}</p>
                      </div>
                      <div className='col-12 col-md-3 d-flex flex-column justify-content-center'>
                        <button className="btn btn-primary ms-3" onClick={() => handleUseCouponClick(coupon)}>Gutschein verwenden</button>
                      </div>
                    </div>
                  </div>
                ))}
                {storeData?.sales?.map((sale, index) => (
                  <div key={index} className='w-100 mb-4' style={{ backgroundColor: "white", borderRadius: 7, boxShadow: '0 4px 8px #c1c1c1', padding: '1rem', position: 'relative' }}>
                 {sale.ConfirmOffer === '1' && (
                          <div style={{ 
                            position: 'absolute', 
                            top: 0, 
                            left: 0, 
                            backgroundColor: "red", 
                            color: "white", 
                            fontFamily: "Poppins", 
                            fontWeight: "bold", 
                            fontSize: "1rem", 
                            padding: '0.1rem 1rem',
                            borderRadius: '7px 0 7px 0'
                          }}>
                            {sale.topOffer}
                          </div>
                        )}
                    <div className='row' style={{ height: '100%', marginTop: sale.addTopOffer ? '2rem' : '0' }}>
                      <div className='col-12 col-md-2 d-flex flex-column align-items-center justify-content-center' style={{ borderRight: "2px dashed blue", height: '100%',top:5 }}>
                        <div style={{ fontFamily: 'cursive', fontSize: '30px', color: 'blue', fontWeight: 'bolder' }}>
                          {sale.discounts}
                          {sale.discountsIn && (
                            sale.discountsIn === 'Euro' ? <FaEuroSign /> : <BsPercent />
                          )}
                        </div>
                        <h4 style={{ fontSize: "30px" }}>{sale.DiscountsHeading}</h4>
                      </div>
                      <div className='col-12 col-md-7 d-flex flex-column justify-content-center'>
                        <div className="d-flex align-items-center">
                          <h3 style={{ fontFamily: "sans-serif", fontSize: "18px", fontWeight: "bold" }}>{sale.titleSale}</h3>
                        </div>
                        <p style={{ fontSize: "15px", fontWeight: "normal" }}>{sale.saleDescription}</p>
                      </div>
                      <div className='col-12 col-md-3 d-flex flex-column justify-content-center'>
                        <button className="btn btn-primary ms-3" onClick={() => handleShopNowClick(sale)}>Jetzt einkaufen</button>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeTab === 'Code' && (
              <>
                {storeData?.coupons?.map((coupon, index) => (
                  <div key={index} className='w-100 mb-4' style={{ backgroundColor: "white", borderRadius: 7, boxShadow: '0 4px 8px #c1c1c1', padding: '1rem', position: 'relative' }}>
                    {coupon.topOffer == "true" ? (
                      <div style={{ 
                        position: 'absolute', 
                        top: 0, 
                        left: 0, 
                        backgroundColor: "red", 
                        color: "white", 
                        fontFamily: "Poppine", 
                        fontWeight: "bold", 
                        fontSize: "1rem", 
                        padding: '0.1rem 1rem',
                        borderRadius: '7px 0 7px 0'
                      }}>
                        {coupon.ConfirmOffer}
                      </div>
                    ):<></>}
                    <div className='row' style={{ height: '100%', marginTop: coupon.addTopOffer ? '2rem' : '0' }}>
                      <div className='col-12 col-md-2 d-flex flex-column align-items-center justify-content-center' style={{ borderRight: "2px dashed blue", height: '100%',top:5 }}>
                        <div style={{ fontFamily: 'cursive', fontSize: '30px', color: 'blue', fontWeight: 'bolder' }}>
                          {coupon.Discounts}
                          {coupon.DiscountsIn && (
                            coupon.DiscountsIn === 'Euro' ? <FaEuroSign /> : <BsPercent />
                          )}
                        </div>
                        <h4 style={{ fontSize: "30px" }}>{coupon.DiscountsHeading}</h4>
                      </div>
                      <div className='col-12 col-md-7 d-flex flex-column justify-content-center'>
                        <div className="d-flex align-items-center">
                          <h3 style={{ fontFamily: "sans-serif", fontSize: "18px", fontWeight: "bold" }}>{coupon.TitleCoupon}</h3>
                        </div>
                        <p style={{ fontSize: "15px", fontWeight: "normal" }}>{coupon.CouponDescription}</p>
                      </div>
                      <div className='col-12 col-md-3 d-flex flex-column justify-content-center'>
                        <button className="btn btn-primary ms-3" onClick={() => handleUseCouponClick(coupon)}>Gutschein verwenden</button>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeTab === 'Sales' && (
              <>
                {storeData?.sales?.map((sale, index) => (
                  <div key={index} className='w-100 mb-4' style={{ backgroundColor: "white", borderRadius: 7, boxShadow: '0 4px 8px #c1c1c1', padding: '1rem', position: 'relative' }}>
                    {sale.ConfirmOffer == 1 && (
                          <div style={{ 
                            position: 'absolute', 
                            top: 0, 
                            left: 0, 
                            backgroundColor: "red", 
                            color: "white", 
                            fontFamily: "Poppins", 
                            fontWeight: "bold", 
                            fontSize: "1rem", 
                            padding: '0.1rem 1rem',
                            borderRadius: '7px 0 7px 0'
                          }}>
                            {sale.topOffer}
                          </div>
                        )}
                    <div className='row' style={{ height: '100%', marginTop: sale.addTopOffer ? '2rem' : '0' }}>
                      <div className='col-12 col-md-2 d-flex flex-column align-items-center justify-content-center' style={{ borderRight: "2px dashed blue", height: '100%',top:5 }}>
                        <div style={{ fontFamily: 'cursive', fontSize: '30px', color: 'blue', fontWeight: 'bolder' }}>
                          {sale.discounts}
                          {sale.discountsIn && (
                            sale.discountsIn === 'Euro' ? <FaEuroSign /> : <BsPercent />
                          )}
                        </div>
                        <h4 style={{ fontSize: "30px" }}>{sale.DiscountsHeading}</h4>
                      </div>
                      <div className='col-12 col-md-7 d-flex flex-column justify-content-center'>
                        <div className="d-flex align-items-center">
                          <h3 style={{ fontFamily: "sans-serif", fontSize: "18px", fontWeight: "bold" }}>{sale.titleSale}</h3>
                        </div>
                        <p style={{ fontSize: "15px", fontWeight: "normal" }}>{sale.saleDescription}</p>
                      </div>
                      <div className='col-12 col-md-3 d-flex flex-column justify-content-center'>
                        <button className="btn btn-primary ms-3" onClick={() => handleShopNowClick(sale)}>Jetzt einkaufen</button>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeTab === 'Printable' && (
              <p>No Printable Coupons Available</p>
            )}
              
  {/* <div className='col-12 col-md-3 d-flex flex-column align-items-start' style={{ fontFamily: "Poppins" }}>
    <img src={storeData.storeLogo} alt={storeData.storeName} style={{ width: "70%", height: "auto" }} className="img-fluid" />
  </div> */}
  
  <p
  dangerouslySetInnerHTML={{
    __html: storeDescription,
  }}
/>

   
  {/* </div> */}
{/* </div> */}

          </div>
        </div></>:<div style={{display:"flex",justifyContent:"center",marginTop:"5%",marginBottom:"5%"}}><img src='/loading.gif' alt='loading' style={{width:50,height:50}} /></div>}
       


      </div>
      <Modal show={showCouponModal} onHide={handleCloseCouponModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCoupon?.TitleCoupon}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{selectedCoupon?.CouponDescription}</p>
          <div className="d-flex align-items-center">
            <h3>{selectedCoupon?.CouponCode}</h3>
            <button onClick={handleCopyClick} className="btn btn-outline-primary ms-3">
              {copied ? 'Copied!' : <FaCopy />}
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCouponModal}>Close</Button>
          <Button variant="primary" onClick={() => nestCloseCouponModal(selectedCoupon)}>Gutschein verwenden</Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  );
}

export default OpenStore;
