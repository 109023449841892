import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'; // Import autoplay styles

import './swipimage.css';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules'; // Import Autoplay module

function SwipImage() {
  // Array of objects with image URLs (local) and corresponding links
  const slides = [
    { imageUrl: '/bodylab.jpg', link: 'https://getgutschein.de/OpenStore/47/Bodylab24' },
    { imageUrl: '/WhatsApp Image 2024-09-29 at 11.27.31 PM.jpeg', link: 'https://getgutschein.de/OpenStore/80/Buybestgear' },
    { imageUrl: '/hallowerr.jpg', link: 'https://getgutschein.de/shop' },
    { imageUrl: '/joseeee.jpg', link: 'https://getgutschein.de/OpenStore/50/JOSEPH' },
    { imageUrl: '/trip.jpg', link: 'https://getgutschein.de/OpenStore/118/Trip.com' },
    { imageUrl: '/Untitled-4.jpg', link: 'https://getgutschein.de/OpenStore/76/JustFashionNow' },
    { imageUrl: '/WhatsApp Image 2024-10-07 at 10.08.06 PM.jpeg', link: 'https://getgutschein.de/OpenStore/76/JustFashionNow' },
    { imageUrl: '/zoopus.jpg', link: 'https://getgutschein.de/OpenStore/94/Zooplus' },
  ];

  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      autoplay={{
        delay: 3000, // Time delay between slides in milliseconds (3 seconds)
        disableOnInteraction: false, // Continue autoplay even after user interaction
      }}
      modules={[Pagination, Autoplay]} // Include Autoplay module
      className="mySwiper"
    >
      {/* Loop through the slides array */}
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          {/* Link around the image */}
          <a href={slide.link} target="_blank" rel="noopener noreferrer">
            <img 
              src={slide.imageUrl} 
              alt={`Slide ${index + 1}`} 
              className="swiper-slide-image" // Add class to apply uniform styles
            />
          </a>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default SwipImage;
